table {
    border-collapse: collapse;
    th, td {
        border: 2px #DDD solid;
        text-align: center;
        padding: 4px;
    }
    thead {
        background-color: #666;
        color: white;
    }
}
pre:has(code) {
    background-color: #222;
    color: #DDD;
}