.HWP {
    width: calc(100% - 32px);
    margin: auto;

    .Header {
        img {
            margin-right: 10px;
        }
        *:nth-child(n) {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .DragDrop {
        width: calc(100% - 32px);
        max-width: 400px;
        height: 200px;
        margin: auto;
        background-color: rgb(222,222,222);

        display: flex;
        flex-direction: column;
        justify-content: center; align-items: center;

        input {
            display: none;
        }
    }

}