.ScriptGen {
    display: block;
    min-height: 100vh;
    position: relative;
    background-color: #EEE;

    .Header {
        padding: 20px;
        font-size: 2rem;
        font-weight: bold;
    }
}